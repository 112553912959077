<template>
  <div class="carouselBox">
    <el-carousel trigger="click" height="216px" indicator-position="none">
      <el-carousel-item
        v-for="(item,index) in dataList"
        :key="index"
        @click.native="toNews(item.infomationId)"
      >
        <img class="rollPic" :src="item.imgFile" alt />
        <div class="rollFont">
          <p>{{ item.imgTitle }}</p>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataList: []
    };
  },
  created() {
    this.getData();
  },
  methods: {
    toNews(id) {
      this.$router.push({
        name: "plocydetail",
        query: {
          idx: 0,
          id: id
        }
      });
    },
    async getData() {
      const api = this.$fetchApi.homeBanner.api;
      const data = await this.$fetchData.fetchPost(
        {
          appId: "nanfan",
          scenariosId: "d3b5095c64e055cda1b53efb284c7d3d",
          status: 0
        },
        api,
        "json"
      );
      if (data.code === "200") {
        this.dataList = data.result;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.carouselBox {
  .el-carousel__button {
    width: 5px;
    height: 5px;
    border-radius: 25%;
  }
  .rollPic {
    width: 100%;
    height: 216px;
  }
  .rollFont {
    width: 100%;
    height: 60px;
    color: #fff;
    font-size: 14px;
    z-index: 1;
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    line-height: 50px;
    p {
      padding: 0 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
