<template>
    <div class='auto-center content clearfix'>
        <div class='infoLeft fl'>
            <index-left></index-left>
        </div>
        <div class='mainCenter fl'>
            <div class='flex'><img :src="require('@/assets/images/index/bg_icon1@2x.png')" alt=""></div>
            <div class='flex'><img :src="require('@/assets/images/index/bg_icon2.png')" alt=""></div>
        </div>
        <div class='inforight fl '>
            <index-right></index-right>
        </div>
    </div>
</template>
<script>
import indexLeft from './component/indexLeft.vue';
import indexRight from './component/indexRight.vue';

export default {
    components: {
        indexLeft,
        indexRight
    },
  data() {
    return {
      startTime: null,
      pageName: "首页(index.vue)",
    };
  },
  beforeCreate() {
    this.startTime = performance.now();
    let this_ = this;
    //1.页面停留时间
    window.addEventListener('beforeunload', function() {
      let endTime = performance.now();
      let stayTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("stayTime", stayTime);

      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          stayTime: stayTime,
        }
      })
    });
    //3.页面加载时间
    window.addEventListener('load', function() {
      let endTime = performance.now();
      let loadTime = ((endTime - this_.startTime)/1000).toFixed(1);
      console.log("loadTime", loadTime);
      window.SZHN_FIRE_BEAR?.trackEvent({
        key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
        segmentation: {
          loadTime: loadTime,
        }
      })
    });
  },
  created() {
    //2.用户浏览量
    window.SZHN_FIRE_BEAR?.trackEvent({
      key: this.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
      segmentation: {
        pageViews: 1,
      }
    })
    //4.网络请求状态
    this.trackNetworkRequest("https://nfggy.com");
  },
  methods: {
    trackNetworkRequest(url) {
      let requestStartTime = performance.now();
      let this_ = this;

      fetch(url)
        .then(response => {
          let requestEndTime = performance.now();
          let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
          console.log("requestSuccessTime", requestTime);
          window.SZHN_FIRE_BEAR?.trackEvent({
            key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
            segmentation: {
              requestTime: requestTime,
              requestStatu: "success",
              requestResult: response.toString()
            }
          })
        })
        .catch(error=>{
          let requestEndTime = performance.now();
          let requestTime = ((requestEndTime - requestStartTime)/1000).toFixed(3);
          console.log("requestErrorTime", requestTime);

          window.SZHN_FIRE_BEAR?.trackEvent({
            key: this_.pageName, // 页面埋点需要的key,业务开发自定义或者pm提供，例：点击登录按钮：loginClick
            segmentation: {
              requestTime: requestTime,
              requestStatu: "error",
              requestResult: error.toString()
            }
          })
        })
    },
  }
  };
</script>
<style lang="less" scoped>
.content{
    align-items: flex-start;
}
.infoLeft{
    margin-right:40px;
    padding-top:50px;
}

.mainCenter{
    width: 2px;
	height: 1017px;
    background-color: #e8f0ff;
    margin-right:45px;
    position: relative;
    div {
        position:absolute;
        top:55px;
        left:50%;
        width:40px;
        height:40px;
        margin-left:-21px;
        z-index:10;
        background-color: #ffffff;
        border: solid 2px rgba(51,128,254,.5);
        border-radius: 50%;
        text-align: center;
        justify-content: center;
        img{
            width:19px;
            height:21px;
        }
    }
    div:nth-child(2){
        top:523px;
    }
}
.inforight{
    padding-top:50px;
}
.auto-center{
  width: 95%;
}
.infoLeft{
  width: 33%;
}
.indexLeft{
  width: 100%;
}
.inforight{
  width: 60%;
}

.indexRight{
  width: 100%;
}
</style>
