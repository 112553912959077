<template>
    <div class='indexRight'>
        <div class='top'>
            <div class="right-side">
                <div class="lg-header">
                    <!-- <div class="header-ico">
                        <i class="iconfont icon-dfmz"></i>
                    </div> -->
                    <div class="">
                        <ul class="commonContent clearfix">
                            <li class="zzsb common_card_item" :class='active==1?"active":""' v-on:mouseover="changeActive(1)" v-on:mouseout="removeActive($event)">
                                <div class="card-title" @click="toSeedDeclaration">
                                    <div class='titleName flex'>
                                        <img src="@/assets/images/index/zzsb-icon@2x.png" alt="">
                                        <p>种子申报</p>
                                    </div>
                                </div>
                                <ul class="icon-list clearfix">
                                    <li class='first pzcommon clearfix' >
                                        <a href="http://202.127.42.47:6010/index.aspx" title="品种登记"  class='clearfix' target="_blink">
                                            <i class="iconfont icon-pzdj fl"></i>
                                            <span class='fl'>品种登记</span>
                                        </a>
                                    </li>
                                    <li class='first pzsd clearfix'>
                                        <a href="http://202.127.42.47:6010/SDSite/Home/Index" class='clearfix' title="品种审定" target="_blink">
                                            <i class="iconfont icon-pzsd fl"></i>
                                            <span class='fl'>品种审定</span>
                                        </a>
                                    </li>
                                    <li tag='li' class='first jyba clearfix'>
                                        <a href="http://202.127.42.47:6008/FilingAllQuery.aspx" class='clearfix' title="经营备案" target="_blink">
                                            <i class="iconfont icon-jyba fl"></i>
                                            <span class='fl'>经营备案</span>
                                        </a>
                                    </li>
                                    <li class='pzsd clearfix'>
                                        <a href="http://202.127.42.47:6009/Home/BigDataIndex" class='clearfix' title="品种保护" target="_blink">
                                            <i class="iconfont icon-pzbh fl"></i>
                                            <span class='fl'>品种保护</span>
                                        </a>
                                    </li>
                                    <li class='zzcb clearfix' :to='{name: "seedDeclaration"}' title="种子储备">
                                        <a href="http://202.127.42.47:6010/zzcb/home/bigdataindex" class='clearfix' title="种子储备" target="_blink">
                                            <i class="iconfont icon-zzcb fl"></i>
                                            <span class='fl'>种子储备</span>
                                        </a>
                                    </li>
                                    <li class='pzcommon clearfix' >
                                        <a href="http://202.127.42.47:6006/Home/BigDataIndex" class='clearfix' title="品种推广" target="_blink">
                                            <i class="iconfont icon-pztg fl"></i>
                                            <span class='fl'>品种推广</span>
                                        </a>
                                    </li>
                                  <li class='jyba nfdj clearfix' v-if="false">
<!--                                    <a href="http://202.100.247.86:19881/" title="南繁登记"  class='clearfix' target="_blink">-->
                                    <a href="https://yd.nfggy.com/" title="南繁登记"  class='clearfix' target="_blink">
                                      <i class="iconfont icon-nfdj fl"></i>
                                      <span class='fl'>南繁登记</span>
                                    </a>
                                  </li>
                                </ul>
                            </li>
                            <li class="zwjy common_card_item" :class='active==2?"active":""' v-on:mouseover="changeActive(2)" v-on:mouseout="removeActive($event)">
                                <div class="card-title">
                                    <div class='titleName flex'>
                                        <img src="@/assets/images/index/zwjy-icon@2x.png" alt="">
                                        <!-- <p>统一申报</p> -->
                                        <p>南繁申报</p>
                                    </div>
                                </div>
                                <ul class="icon-list clearfix">
                                    <router-link tag='li' class='clearfix' :to='{name: "plantQuarantine"}' title="申报指南">
                                        <div class='pzcommon fl'><i class="iconfont icon-sbzn"></i></div>
                                        <span class='fl'>申报指南</span>
                                    </router-link>
                                    <router-link tag='li' class='clearfix' :to='{name: "plantQuarantine"}' title="检疫查询">
                                        <div class='pzsd fl'><i class="iconfont icon-jyjc"></i></div>
                                        <span class='fl'>检疫查询</span>
                                    </router-link>
                                    <router-link tag='li' class='clearfix' :to='{name: "plantQuarantine"}' title="产检申请">
                                        <div class='jyba fl'><i class="iconfont icon-cjsq"></i></div>
                                        <span class='fl'>产检申请</span>
                                    </router-link>
                                    <router-link tag='li' class='clearfix' :to='{name: "plantQuarantine"}' title="调检申请">
                                        <div class='zzcb fl'><i class="iconfont icon-djsq"></i></div>
                                        <span class='fl'>调检申请</span>
                                    </router-link>
                                    <li class='clearfix'>
<!--                                    <a href="http://202.100.247.86:19881/" title="南繁登记" style="margin-top: 0.05rem;"  class='clearfix' target="_blink">-->
                                    <a href="https://yd.nfggy.com/" title="南繁登记" style="margin-top: 0.05rem;"  class='clearfix' target="_blink">
                                        <div class='jyba fl'><i class="iconfont icon-nfdj fl" style="margin-bottom: 0.05rem;"></i></div>
                                      <span class='fl'>南繁登记</span>
                                    </a>
                                  </li>
                                </ul>
                                <ul class="btn-list" v-if="false">
                                    <li class="btn-red">
                                        <div>
                                            <a  href='https://ywglyh.moa.gov.cn/idp/authcenter/ActionAuthChain?entityId=HHGX' target="_blink">
                                                <i class="iconfont icon-jumin"></i>全国植物检疫信息化管理系统 管理端
                                            </a>
                                        </div>
                                    </li>
                                    <li class="btn-green">
                                        <div>
                                            <a href='https://ywglyh.moa.gov.cn/idp/authcenter/ActionAuthChain?entityId=HHGX' target="_blink">
                                                <i class="iconfont icon-jumin1"></i>全国植物检疫信息化管理系统 企业端
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li class="zybg common_card_item" :class='active==3?"active":""' v-on:mouseover="changeActive(3)" v-on:mouseout="removeActive($event)">
                                <div class="card-title">
                                    <div class='titleName flex'>
                                        <img src="@/assets/images/index/zybg-icon@2x.png" alt="">
                                        <!-- <p>种业报告</p> -->
                                        <p>数据报告</p>
                                    </div>
                                </div>
                                <ul class="icon-list three-last clearfix">
                                    <li class='pzcommon fl' @click="$message.success('敬请期待')">
                                        <a class='clearfix' target="_blank" title="2020年全国种业报告">
                                            <i class="iconfont icon-susongshenqing fl"></i>
                                            <span class='fl'>2020年全国<br/>种业报告</span>
                                        </a>
                                    </li>
                                    <li class='pzcommon fl'>
                                        <a class='clearfix' target="_blank" href='https://hnnmy.oss-cn-shanghai.aliyuncs.com/file/nanfan-pdf/2019年中国种业发展报告.pdf#toolbar=0&navpanes=0&scrollbar=0' title="2019年全国种业报告">
                                            <i class="iconfont icon-susongshenqing fl"></i>
                                            <span class='fl'>2019年全国<br/>种业报告</span>
                                        </a>

                                    </li>
                                    <li class='pzcommon fl'>
                                        <a class='clearfix' target="_blank" href='https://hnnmy.oss-cn-shanghai.aliyuncs.com/file/nanfan-pdf/2018年中国种业发展报告.pdf#toolbar=0&navpanes=0&scrollbar=0' title="2014年全国种业报告">
                                            <i class="iconfont icon-yiwangtongban fl"></i>
                                            <span class='fl'>2018年全国<br/>种业报告</span>
                                        </a>

                                    </li>
                                    <li class='pzsd fl'>
                                         <a class='clearfix ' target="_blank" href='https://hnnmy.oss-cn-shanghai.aliyuncs.com/file/nanfan-pdf/2017年中国种业发展报告.pdf' title="2014年全国种业报告">
                                            <i class="iconfont icon-kaijuzhengming fl"></i>
                                            <span class='fl'>2017年全国<br/>种业报告</span>
                                        </a>
                                    </li>
                                    <li class='zzcb fl'>
                                        <a class='clearfix' target="_blank" href='https://hnnmy.oss-cn-shanghai.aliyuncs.com/file/nanfan-pdf/2016年中国种业发展报告.pdf' title="2016年全国种业报告">
                                            <i class="iconfont icon-jiancha1 fl"></i>
                                            <span class='fl'>2016年全国<br/>种业报告</span>
                                        </a>
                                    </li>
                                    <li class='jyba fl'>
                                       <a class='clearfix' target="_blank" href='https://hnnmy.oss-cn-shanghai.aliyuncs.com/file/nanfan-pdf/2015年中国种业发展报告.pdf' title="2015年全国种业报告">
                                            <i class="iconfont icon-susongshenqing fl"></i>
                                            <span class='fl'>2015年全国<br/>种业报告</span>
                                        </a>
                                    </li>
                                    <li class='pzcommon fl'>
                                        <a class='clearfix' target="_blank" href='https://www.zgnf.net/profile/resources/2022/uploads/shhfw/contract/2023%E5%B9%B4%E4%B8%AD%E5%9B%BD%E5%86%9C%E4%BD%9C%E7%89%A9%E7%A7%8D%E4%B8%9A%E5%8F%91%E5%B1%95%E6%8A%A5%E5%91%8A0104-%E6%B0%B4%E5%8D%B0.pdf#toolbar=0&navpanes=0&scrollbar=0' title="2023年南繁硅谷发展指数报告">
                                            <i class="iconfont icon-susongshenqing fl"></i>
                                            <span class='fl'>2023年中国农作物<br/>种业发展报告</span>
                                        </a>

                                    </li>
                                    <li class='pzcommon fl'>
                                        <a class='clearfix' target="_blank" href='https://nanfan-index.idx365.com/hai-nan-webapi/profile/avatar/2024/05/11/%E5%8D%97%E7%B9%81%E7%A1%85%E8%B0%B7%E5%8F%91%E5%B1%95%E6%8C%87%E6%95%B0%E6%8A%A5%E5%91%8A2023.pdf#toolbar=0&navpanes=0&scrollbar=0' title="2023年南繁硅谷发展指数报告">
                                            <i class="iconfont icon-susongshenqing fl"></i>
                                            <span class='fl'>2023年南繁硅谷<br/>发展指数报告</span>
                                        </a>

                                    </li>
                                </ul>
                            </li>
                            <!-- <li class="zybg common_card_item" :class='active==3?"active":""' v-on:mouseover="changeActive(3)" v-on:mouseout="removeActive($event)">
                                <div class="card-title">
                                    <div class='titleName flex'>
                                        <img src="@/assets/images/index/zybg-icon@2x.png" alt="">
                                        <p>南繁指数</p>
                                    </div>
                                </div>
                                <ul class="icon-list three-last clearfix">
                                    <li class='pzcommon fl'>
                                        <a class='clearfix' target="_blank" href='https://nanfan-index.idx365.com/website/#/data' title="指数数据">
                                            <i class="iconfont icon-zhishushuju fl"></i>
                                            <span class='fl'>指数数据</span>
                                        </a>

                                    </li>
                                    <li class='jyba fl'>
                                        <a class='clearfix' target="_blank" href='https://nanfan-index.idx365.com/website/#/comment' title="指数报告">
                                            <i class="iconfont icon-zhishubaogao fl"></i>
                                            <span class='fl'>指数报告</span>
                                        </a>

                                    </li>
                                    <li class='pzsd fl' style="margin-right: 20px;">
                                        <a class='clearfix' target="_blank" href='https://nanfan-index.idx365.com/manage/#/login?redirect=%2Findex' title="管理系统">
                                            <i class="iconfont icon-guanlixitong fl"></i>
                                            <span class='fl'>管理系统</span>
                                        </a>

                                    </li>
                                    <li class='zzcb fl'>
                                         <a class='clearfix ' target="_blank" href='https://nanfan-index.idx365.com/dataShow/#/index' title="南繁驾驶舱">
                                            <i class="iconfont icon-jiashicang fl"></i>
                                            <span class='fl'>南繁驾驶舱</span>
                                        </a>
                                    </li>
                                </ul>
                            </li> -->
                        </ul>
                    </div>
                </div>

            </div>
        </div>
        <div class='bottom'>
            <div class='flex serviceContent'>
                <ul class='serviceCard'>
                    <router-link tag='li' class='flex' :to='{name:item.name}' v-for='(item,index) in serviceCard' :key='index+item.name'>
                        <img :src="item.imgUrl" alt="">
                        <p>{{item.text}}</p>
                    </router-link>
                </ul>
                <div class='serviceList'>
                    <div class='title'>热点服务</div>
                    <div v-for='(item, index) in serviceList' :key='index + item.title' class='cardItem'>
                        <p class='title'>{{item.title}}</p>
                        <ul class='flex' :class='(index==serviceList.length-1)?"last":""'>
                            <router-link tag='li' :to='{name:itm.name,query:{id: itm.id, type: itm.type}}' v-for='(itm, idx) in item.children' :key='idx+itm.text'>
                                {{itm.text}}
                            </router-link>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
             serviceCard: [{
                text: '专利检索',
                name: 'patentSearch',
                imgUrl: require('@/assets/images/index/zljs@2x.png')
            }, {
                text: '实验室预约',
                name: 'labShare',
                imgUrl: require('@/assets/images/index/sysyy@2x.png')
            }, {
                text: '重点项目',
                name: 'nanFanMajorList',
                imgUrl: require('@/assets/images/index/zdxm@2x.png')
            }, {
                text: '专利维权',
                name: 'patentRights',
                imgUrl: require('@/assets/images/index/zlwq@2x.png')
            }],
            serviceList: [
                {
                    title: '第三方服务',
                    children: [{
                        text: '法律服务',
                        name: 'publicService',
                        type: 1
                    }, {
                        text: '金融服务',
                        name: 'publicService',
                        type: 3
                    }, {
                        text: '科创服务',
                        name: 'publicService',
                        type: 4
                    }]
                }, {
                    title: '生物育种专区',
                    children: [{
                        text: '病虫害图库',
                        name: 'pestData'
                    },
                    // {
                    //     text: '补贴政策',
                    //     name: 'policyMatching'
                    // },
                    {
                        text: '实验室管理',
                        name: 'laboratoryInfo'
                    },
                    {
                        text: '转基因申报',
                        name: 'transgenicRegistration'
                    }]
                }, {
                    title: '交易市场',
                    children: [{
                        text: '化肥农药交易',
                        name: 'agriculturalAssets',
                        id: '1'
                    }
                    , {
                    //     text: '化肥农药交易',
                    //     name: 'agriculturalAssets',
                    //     id: '6736af6d6d5972fd40879b3480bcac6d'
                    // }, {
                        text: '农机交易',
                        name: 'farmingMachinery',
                        id: '58058a421ea2ce524b92b1f81a439a9e'
                    }, {
                        text: '土地租赁',
                        name: 'landLeasing',
                        id: 'c438df12cc4bd6626d3418978146562c'
                    }
                    // {
                    //     text: '冷链车辆',
                    //     name: 'coldChainCar',
                    //     id: '7842339ceb84ba2e66fa7876052bb545'
                    // }
                    ]
                }, {
                    title: '专利及应用',
                    children: [{
                        text: '成果转化',
                        name: 'achievementList'
                    }, {
                        text: '专利评估',
                        name: 'patentAssess'
                    }, {
                        text: '品种权检索',
                        name: 'varietySearch'
                    }]
                }

            ],
            active: 2
        };
    },
    methods: {
        changeActive(activeIndex){
            this.active = activeIndex;
        },
        removeActive($event){
            // $event.currentTarget.className="";
        },
        toSeedDeclaration(){
            this.$router.push({
                path: "/seedDeclaration"
            });
        }
    }
};
</script>
<style lang="less" scoped>
    .indexRight{
        .bottom{
            margin-top:55px;
            .serviceContent{
                align-items: flex-start;
            }
            div.title{
                color: #3380fe;
                font-size: 22px;
                text-align:center;
                margin-bottom:26px;
                font-weight: bold;
                line-height: 20px;
                // padding-top:20px;
            }
            div.title::after{
                //width:202px;
                //height:2px;
                content:'';
                display: block;
                width: 100%;
                height: 1px;
                background-image: linear-gradient(
                90deg,
                #ffffff 0%,
                #d1e2fe 10%,
                #b4cffd 20%,
                #699ffa 30%,
                #265ae0 50%,
                #699ffa 70%,
                #b4cffd 80%,
                #d1e2fe 90%,
                #ffffff 100%
                ),
                linear-gradient(#265ae0, #265ae0);
                margin:9px auto 0;
            }
            .serviceCard{
                position: relative;
                padding-top:80px;
                width:33%;
                li {
                    width: 87%;
                    height: 76px;
                    border-radius: 10px;
                    opacity: 0.8;
                    color: #fff;
                    font-size: 18px;
                    margin-bottom:15px;
                    cursor: pointer;
                    transition: 0.5s;
                    justify-content: center;
                    img{
                        margin:0 20px;
                    }
                }
                li:nth-child(1){
                   background-image: linear-gradient(90deg,
                    #265ae1 0%,
                    #448afc 100%),
                linear-gradient(
                    #448afc,
                    #448afc);
                background-blend-mode: normal,
                    normal;
                }
                li:nth-child(1):hover{
                    background-image: linear-gradient(90deg,
                    #448afc 0%,
                    #265ae1 100%),
                linear-gradient(
                    #6299f1,
                    #6299f1);
                background-blend-mode: normal,
                    normal;
                    img{
                        margin-right:10px;
                    }
                }
                li:nth-child(2){
                     background-image: linear-gradient(90deg,
                    #12a15f 0%,
                    #a6df62 100%),
                linear-gradient(
                    #448afc,
                    #448afc);
                background-blend-mode: normal,
                    normal;
                }
                li:nth-child(2):hover{
                    background-image: linear-gradient(90deg,
                    #a6df62 0%,
                    #12a15f 100%),
                linear-gradient(
                    #d0ddf6,
                    #d0ddf6);
                background-blend-mode: normal,
                    normal;
                    img{
                        margin-right:10px;
                    }
                }
                li:nth-child(3){
                    background-image: linear-gradient(90deg,
                    #e7523e 0%,
                    #efbe48 100%),
                linear-gradient(
                    #448afc,
                    #448afc);
                background-blend-mode: normal,
                    normal;
                }
                 li:nth-child(3):hover{
                     background-image: linear-gradient(90deg,
                    #efbe48 0%,
                    #e7523e 100%),
                linear-gradient(
                    #e8f0ff,
                    #e8f0ff);
                background-blend-mode: normal,
                    normal;
                    img{
                        margin-right:10px;
                    }
                 }
            }
            li:nth-child(4){
                background-image: linear-gradient(90deg,
                #524af3 0%,
                #bf87d1 100%),
            linear-gradient(
                #448afc,
                #448afc);
            background-blend-mode: normal,
                normal;
            }
            li:nth-child(4):hover{
                background-image: linear-gradient(90deg,
                    #bf87d1 0%,
                    #524af3 100%),
                linear-gradient(
                    #e8f0ff,
                    #e8f0ff);
                background-blend-mode: normal,
                    normal;
                    img{
                        margin-right:10px;
                    }
                }

            .serviceCard::after{
                content: "";
                display: inline-block;
                width: 1px;
                height: 430px;
                background-color: rgba(38,90,225,.1);
                position: absolute;
                right: 0;
                top: 0;
            }
            .serviceList{
                padding-top:21px;
                margin-left:40px;
                width: 60%;
                p.title{
                    color: #3380fe;
                    font-size: 18px;
                }
                p.title::before{
                    content:'';
                    width: 4px;
                    height: 20px;
                    background-color: #3380fe;
                    display: inline-block;
                    margin-right:9px;
                    position:relative;
                    top:3px;
                }
                .cardItem{
                    margin-bottom:15px;
                    ul{
                        margin-top:20px;
                        font-size: 16px;
                        color:#333;
                        border-bottom:1px solid rgba(38,90,225,.1);
                        padding-bottom:20px;
                        padding-left:4px;
                        li{
                            cursor: pointer;
                            width: 30%;
                            line-height: 16px;
                        }
                        li:nth-child(2n){
                            margin:0 40px;
                        }
                        li::before{
                            width: 6px;
                            height: 6px;
                            background-color: rgba(151,151,151,.5);
                            border-radius:50%;
                            content:'';
                            display: inline-block;
                            margin-right:8px;
                            position: relative;
                            top:-3px;
                        }
                        li:hover{
                            color:#3380fe;
                        }
                    }
                    .last{
                        border-bottom:none;
                    }
                }
            }

        }
    }
    .top{
        height:410px;
    }
    .common_card_item{
        float:left;
        //width: 155px;
        width: 22%;
        font-size: 16px;
        text-align: center;
        border: solid 1px rgba(38,90,225,.1);
        color: rgba(51,51,51,.8);
        margin-right:-1px;
        height:369px;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1), z-index 0s 0.12s;
        li{
            padding:8.5px 0;
            border-bottom:1px solid rgba(38,90,225,.1);
            padding-left: 30%;
        }
        li:last-child{
            border-bottom: none;
        }
        .titleName{
            flex-direction: column;
            justify-content: center;
            height:100%;
            display: flex;
        }
        .iconfont{
            background:url('../../../assets/images/index/pzdj@2x.png') no-repeat;
            background-size:cover;
            width: 23px;
            height: 24px;
            display: inline-block;
            margin-right:8px;
        }
        .icon-nfdj{
            background:url('../../../assets/images/index/nfdj@2x.png') no-repeat;
            background-size:cover;
        }
        .icon-pzsd{
            background:url('../../../assets/images/index/pzsd@2x.png') no-repeat;
            background-size:cover;
        }
        .icon-jyba{
            background:url('../../../assets/images/index/jyba@2x.png') no-repeat;
            background-size:cover;
        }
        .icon-pzbh{
            background:url('../../../assets/images/index/pzbh@2x.png') no-repeat;
            background-size:cover;
        }
        .icon-pztg{
            background:url('../../../assets/images/index/pztg@2x.png') no-repeat;
            background-size:cover;
        }
        .icon-zzcb{
            background:url('../../../assets/images/index/zzcb@2x.png') no-repeat;
            background-size:cover;
        }
        .icon-sbzn{
            background:url('../../../assets/images/index/sbzn@2x.png') no-repeat;
            background-size:cover;
        }
        .icon-jycx{
            background:url('../../../assets/images/index/jycx@2x.png') no-repeat;
            background-size:cover;
        }
        .icon-cjsq{
            background:url('../../../assets/images/index/cjsq@2x.png') no-repeat;
            background-size:cover;
        }
        .icon-djsq{
            background:url('../../../assets/images/index/djsq@2x.png') no-repeat;
            background-size:cover;
        }

        .card-title{
            height: 80px;
            background: rgba(38,90,225,.1);
            text-align: center;
            color: #3380fe;
            font-size: 18px;
            border-right:1px solid rgba(38,90,225,.1);
        }
        .btn-list{
            display: none;
        }
        .three-last{
            li{
                //text-align: center;
                //padding-left:20px;
                i{
                    margin-top:5px;
                }
                a{
                    display: block;
                }
            }
        }
    }
    .zwjy{
       li{
            padding:22px 0 22px 30%;
        }
    }
    .zybg{
        li{
            //padding:9.9px 0;
            .iconfont{
                background:url('../../../assets/images/index/zybg@2x.png') no-repeat;
                background-size:cover;
            }
            span{
                line-height: 19px;
            }
        }
    }
    .active{
        width: 55%;
        border: solid 1px #265ae1;
        height:410px;
        margin-top:-20px;
        overflow: hidden;
        .icon-list{
            text-align: center;
            i{
                margin-top:15px;
            }
            i, span, div{
                float:none;
                text-align: center;
            }
            span{
                display: block;
            }
        }
        .card-title{
            width: 100%;
            background: url('../../../assets/images/index/zzsb@2x.png') no-repeat;
            background-size:cover;
            color: #ffffff;
            text-align: left;
            height: 90px;
            line-height: 90px;
            margin-bottom:18px;
        }
        .icon-list{
            margin-bottom:29px;
            font-size:16px;
            .pzcommon{
                color:#fd6666;
                background-color: rgba(253,102,102,.1);
                transition: 0.6s;
            }
            .pzcommon:hover{
                background-color: rgba(253,102,102,.3);
            }
            .pzsd{
                color:#fc9b44;
                background: rgba(252,198,68,.1);
                transition: 0.6s;
            }
            .pzsd:hover{
                background: rgba(252,198,68,.3);
            }
            .nfdj{
                margin-top: 10px;
            }
            .jyba{
                background: rgba(68,138,252,.1);
                color:#448afc;
                transition: 0.6s;
            }
            .jyba:hover{
                background: rgba(68,138,252,.3);
            }
            .zzcb{
                background: rgba(64,168,137,.1);
                color:#40a889;
                transition: 0.6s;
            }
            .zzcb:hover{
                background: rgba(64,168,137,.3);
            }
            li{
                width: 26%;
                height: 100px;
                border-radius: 10px;
                margin-left:21px;
                padding:0;
                float: left;
                flex-direction: column;
                border-bottom:0;
                .iconfont{
                    width: 35px;
                    height: 36px;
                    margin-bottom: 0px;
                    margin-right:0;
                }
                .icon-pzsd{
                    background:url('../../../assets/images/index/pzsd-y@2x.png') no-repeat;
                    background-size:cover;
                }
                .icon-jyba{
                    background:url('../../../assets/images/index/jyba-b@2x.png') no-repeat;
                    background-size:cover;
                }
                .icon-pzbh{
                    background:url('../../../assets/images/index/pzbh-y@2x.png') no-repeat;
                    background-size:cover;
                }
                .icon-pztg{
                    background:url('../../../assets/images/index/pztg-r@2x.png') no-repeat;
                    background-size:cover;
                }
                .icon-zzcb{
                    background:url('../../../assets/images/index/zzcb-g@2x.png') no-repeat;
                    background-size:cover;
                }
                .icon-nfdj{
                    background:url('../../../assets/images/index/nfdj-b@2x.png') no-repeat;
                    background-size:cover;
                }
                .icon-pzdj{
                    background:url('../../../assets/images/index/pzdj-r@2x.png') no-repeat;
                    background-size:cover;
                }
                .icon-guanlixitong{
                    background:url('../../../assets/images/index/guanlixitong.png') no-repeat;
                    background-size:cover;
                }
                .icon-zhishushuju{
                    // background:url('../../../assets/images/index/zybg-y@2x.png') no-repeat;
                    background:url('../../../assets/images/index/zhishushuju.png') no-repeat;
                    background-size:cover;
                }
                .icon-zhishubaogao{
                    // background:url('../../../assets/images/index/zybg-y@2x.png') no-repeat;
                    background:url('../../../assets/images/index/zhishubaogao.png') no-repeat;
                    background-size:cover;
                }
                .icon-jiancha1{
                    background:url('../../../assets/images/index/zybg-g@2x.png') no-repeat;
                    background-size:cover;
                }
                .icon-jiashicang{
                    background:url('../../../assets/images/index/jiashicang.png') no-repeat;
                    background-size:cover;
                }
                .icon-sbzn{
                    background:url('../../../assets/images/index/sbzn-b@2x.png') no-repeat;
                    background-size:cover;
                }
                .icon-jyjc{
                    background:url('../../../assets/images/index/jycx-b@2x.png') no-repeat;
                    background-size:cover;
                }
                .icon-cjsq{
                    background:url('../../../assets/images/index/cjsq-b@2x.png') no-repeat;
                    background-size:cover;
                }
                .icon-djsq{
                    background:url('../../../assets/images/index/djsq-b@2x.png') no-repeat;
                    background-size:cover;
                }
            }
            li.first{
                margin-bottom:10px;
            }
        }
        .three-last{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            font-size:16px;
            padding:10px 10px;
            box-sizing: border-box;
            li{
                flex: 1 0 35%;
                margin-right:10;
                a{
                    display: flex;
                }
            }
            .pzcommon, .pzsd{
                margin-bottom:20px;
            }
        }

    }
    .zwjy.active{
        .card-title{
            background: url('../../../assets/images/index/zwjy@2x.jpg') no-repeat;
            background-size:cover;
        }
        .icon-list{
            margin:0 auto 53px;
            li{
                background: #fff;
                height:auto;
                width: 20%;
                font-size:16px;
                display: flex;
                align-items: center;
                div{
                    width: 72px;
                    height: 72px;
                    display: table-cell;
                    vertical-align: middle;
                    text-align: center;
                    border-radius: 50%;

                }
                i{
                    width: 34px;
                    height: 34px;
                    margin-bottom:0;
                    display: inline-block;
                    margin-top:20px;
                    margin-left: 2px;
                }
                span{
                    margin-top:10px;
                }
            }
            li:nth-child(1){
                color:#fd6666;
            }
            li:nth-child(2){
                color:#fc9b44;
            }
            li:nth-child(3){
                color:#448afc;

            }
            li:nth-child(4){
               color:#40a889;
            }
            li:nth-child(5){
               color:#448afc;
            }
        }
        .btn-list{
            display: block;

            .btn-red{
                width: 342px;
                height: 42px;
                border-radius: 10px;
                background: rgba(rgba(68,138,252,.5))
            }
            li:hover{
                background: rgba(68,138,252,.8);
            }
            li{
                width: 342px;
                height: 42px;
                border-radius: 10px;
                background: rgba(68,138,252,.5);
                padding:0;
                color:#fff;
                font-size:16px;
                margin: 0 auto 20px;
                div{
                    height:42px;
                    line-height: 42px;
                }
                i{
                    width:26px;
                    height:23px;
                    position: relative;
                    top:4px;
                }
                .icon-jumin{
                    background: url('../../../assets/images/index/gld@2x.png') no-repeat;
                    background-size:cover;
                }
                .icon-jumin1{
                    background: url('../../../assets/images/index/qyd@2x.png') no-repeat;
                    background-size:cover;
                }

            }
            .btn-green{
                background: rgba(rgba(38,90,225,.5))
            }

        }
    }
    .zybg.active{
        .card-title{
             background: url('../../../assets/images/index/zzbg-td@2x.png') no-repeat;
             background-size:cover;
        }
        li{
            margin: 0 1%;
            width:28%;
            display: flex;
            height: 55px;
            padding-top: 5px;
            box-sizing: border-box;
            i{
            }
        }
        li:nth-child(3n+3){
            margin-right:0;
        }
        li:nth-child(n+3){
            margin: 0 1%;
            margin-bottom:20px;
        }
        a{
            justify-content: center;
            align-items: center;
        }
    }
    .active{
        .titleName{
            display: none;
        }
    }
    .zzsb.active{
      height: 445px;
    }
    .zzsb li{
      padding-top: 8.2px;
      padding-bottom: 8.2px;
    }
</style>
