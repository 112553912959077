<template>
  <div class="swiper-container">
    <div class="swiper-wrapper" @mouseenter="startSwiper" @mouseleave="stopSwiper">
      <router-link
        tag="div"
        :to="{
          name:'plocydetail',query:{
          idx:0,
          id:item.infomationId}
        }"
        class="swiper-slide"
        v-for="(item,index) in swiperData"
        :key="index"
      >
        <img :src="item.imgFile" alt />
        <div class="bottom_slider">
          <p class='ellipsis'>{{item.imgTitle}}</p>
        </div>

      </router-link>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination square"></div>
  </div>
</template>
<script>
import Swiper from "swiper";
export default {
  data() {
    return {
      swiperData: [],
      bannerSwiper: null
    };
  },
  created() {
    this.getData();
  },
  activated() {
    if (this.bannerSwiper) {
      this.bannerSwiper.autoplay.start();
    }
  },
  methods: {
    startSwiper() {
      this.bannerSwiper && this.bannerSwiper.autoplay.stop();
    },
    stopSwiper() {
      this.bannerSwiper && this.bannerSwiper.autoplay.start();
    },
    async getData() {
      const api = this.$fetchApi.homeBanner.api;
      const data = await this.$fetchData.fetchPost(
        {
          appId: "nanfan",
          scenariosId: "d3b5095c64e055cda1b53efb284c7d3d",
          status: 0
        },
        api,
        "json"
      );
      if (data.code === "200") {
        this.swiperData = data.result;
        this.$nextTick(() => {
          this.swiper();
        });
      }
    },
    // 轮播
    swiper() {
      this.bannerSwiper = new Swiper(".swiper-container", {
        direction: "horizontal",
        loop: true, // 循环模式选项
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        pagination: {
          el: ".swiper-pagination",
          // dynamicBullets: false,
          // dynamicMainBullets: 3,
          bulletElement: "li",
          clickable: true
        }
      });
    }
  }
};
</script>
<style scoped>
.swiper-container >>> .swiper-pagination-bullet {
  /* border: 2px solid #fff;
  background-color: transparent;
  opacity: 1; */
  background: rgba(255,255,255,.6);
  width: 9px;
  height: 9px;
}
.swiper-container >>> .swiper-pagination-bullet-active {
  background-color: #fff;
}
</style>
<style lang="less" scoped>
.swiper-container {
  position: relative;
  width: 100%;
  height: 216px;
  .swiper-slide {
    cursor: pointer;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .bottom_slider {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 53px;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 100;
      p {
        width: 70%;
        font-family: PingFang-SC-Regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 53px;
        letter-spacing: 0px;
        color: #ffffff;
        text-indent: 14px;
      }
    }
  }
  .square {
    position: absolute;
    bottom: 18px;
    left: 75% !important;
    width: 25%;
    z-index: 101;
  }
}
</style>
