<template>
  <div class="indexLeft">
    <div>
      <div class="search_input">
        <div class="left_search">
          <el-input v-model="input"></el-input>
        </div>
        <div class="search_icon" @click="onSearch">
          <img
            title="点击搜索"
            src="http://hnnmy.oss-cn-shanghai.aliyuncs.com/file/demand/static/2019-11-01/1e061c1e-6b7f-4161-8c95-ad83c90ac6ab.png"
            alt
          />
        </div>
      </div>
      <div class="hotSearch">
        热门搜索：
        <router-link
          tag="a"
          :to='{name:"searchNews",query: { keyWord: item}}'
          v-for="(item, index) in keyWordsList"
          :key="index"
        >{{item}}</router-link>
      </div>
    </div>
    <div>
      <!-- <banner-nav></banner-nav> -->
      <carousel></carousel>
    </div>
    <!-- 今日南繁 -->
    <div class="consultation">
      <router-link tag="p" :to='{name:"nanfanNews"}' class="title newsTitle">今日南繁</router-link>
      <ul class="newsList">
        <router-link
          tag="li"
          :to="{name:'plocydetail',query:{id:item.informationId, idx:item.index}}"
          class="ellipsis"
          v-for="(item, index) in newsList"
          :key="index"
        >{{item.columnName}} | {{item.title}}</router-link>
      </ul>
    </div>
    <!-- 数据查询 -->
    <div class="consultation">
      <p class="title">数据查询</p>
      <ul class="flex justify_content_between dataQuery">
        <li class="ellipsis" v-for="(item, index) in dataShareList" :key="index">
          <router-link
            :to="{name:item.name}"
            v-if="item.name"
            tag="div"
            class="flex flex_direction_column dataQueryItem"
          >
            <div>
              <img :src="item.imgUrl" alt />
            </div>
            <p>{{item.text}}</p>
          </router-link>
          <div v-else class="flex flex_direction_column dataQueryItem">
            <a :href="item.path" target="_blink">
              <div>
                <img :src="item.imgUrl" alt />
              </div>
              <p>{{item.text}}</p>
            </a>
          </div>
        </li>
      </ul>
    </div>
    <!-- 办事指南 -->
    <div class="consultation">
      <p class="title">办事指南</p>
      <ul class="flex dataQuery businessGuideContent">
        <el-badge value="新" class="item" v-for="(item, index) in businessGuide" :key="index" :hidden="index!==0">
            <li class="ellipsis businessGuide flex" >           
                <div class="flex" @click="onRedirect(item)">
                    <i :style="{backgroundImage: 'url(' + item.imgUrl + ')' }"></i>
                    <p>{{item.text}}</p>
                </div>
            </li>
        </el-badge>
      </ul>
    </div>
  </div>
</template>
<script>
import bannerNav from "./banner.vue";
import carousel from "./carousel.vue";
import configUtils from "@/utils/config";
import { Message } from "element-ui";
const columnIds = [
  "d1707e23348143fb99537c9bddffcdb3",
  "3373f13e83b74f12a213a53db20a6657",
  "abb9308a63f64190b55633b83cc27e12"
];
export default {
  components: {
    bannerNav,
    carousel
  },
  data() {
    return {
      input: "",
      dataShareList: [
        {
          text: "共享发布",
          name: "dataShare",
          imgUrl: require("@/assets/images/index/gxsj@2x.png")
        },
        {
          text: "病虫害数据",
          name: "pestData",
          imgUrl: require("@/assets/images/index/bch@2x.png")
        },
        {
          text: "农机补贴",
          name: "machineSubsidy",
          imgUrl: require("@/assets/images/index/njbt@2x.png")
        },
        {
          text: "种质资源",
          name: "germplasmResource",
          imgUrl: require("@/assets/images/index/zzsj@2x.png")
        },
        {
          text: "种子查询",
          name: "",
          imgUrl: require("@/assets/images/index/zzcx-icon@2x.png"),
          path: "http://202.127.42.47:6010/SDSite/Home/Index"
        }
      ],
      businessGuide: [
        {
          text: "南繁登记",
          name: "nanfanregister",
          imgUrl: require("@/assets/images/index/nfba@2x.png")
        },
        // {
        //   text: "补贴申请",
        //   name: "subsidyDeclare",
        //   imgUrl: require("@/assets/images/index/btsq@2x.png")
        // },
        // {
        //   text: "业务协同",
        //   name: "",
        //   path: configUtils.baseurlAdminUrl + "/nanfan-cms/login.html",
        //   imgUrl: require("@/assets/images/index/nfbg@2x.png")
        // },
        {
          text: "财务申请",
          name: "",
          imgUrl: require("@/assets/images/index/nfbg@2x.png")
        },
        {
          text: "信息报送",
          name: "",
          path: "http://218.77.183.220:8080/zgnf/zgnf/admin/login.do?returnUrl=/zgnf/admin/index.do",
          imgUrl: require("@/assets/images/index/xxbs@2x.png")
        },
        {
          text: "文档管理",
          name: "",
          imgUrl: require("@/assets/images/index/nfbg@2x.png")
        }
      ],
      newsList: [],
      keyWordsList: [],
      timeOut: new Date(),
      isNoNanfanPerson: localStorage.getItem('noPerson')
    };
  },
  mounted() {
    console.log(this.isNoNanfanPerson);
    this.getNewsList();
    this.getKeyWord();
  },
  methods: {
    // 获取新闻列表
    async getNewsList() {
      const param = {
        pageNum: 1,
        pageSize: 5,
        columnIds
      };
      const api = this.$fetchApi.pcNewsList.api;
      const data = await this.$fetchData.fetchPost(param, api, "json");
      if (data.code === "200") {
        if (data.result && data.result.rows) {
          this.newsList = data.result.rows;
          this.newsList.forEach((item, index) => {
            if (item.columnId === columnIds[0]) {
              item.index = 0;
              item.columnName = "要闻";
            }
            if (item.columnId === columnIds[1]) {
              item.index = 1;
              item.columnName = "政策";
            }
            if (item.columnId === columnIds[2]) {
              item.index = 2;
              item.columnName = "公告";
            }
          });
        }
      }
    },
    // 获取搜索关键词
    async getKeyWord() {
      const api = this.$fetchApi.getNewsKeyWords.api;
      const data = await this.$fetchData.fetchPost({}, api, "json");
      if (data.code === "200") {
        if (data.result && data.result.rows) {
          this.keyWordsList = data.result.rows;
        }
      }
    },
    onSearch() {
      this.$router.push({
        name: "searchNews",
        query: {
          keyWord: this.input
        }
      });
    },
    async onRedirect(data) {
      if (data.name) {
        if (data.name === "nanfanRecords") {
          if (new Date() - this.timeOut < 2000) {
            return;
          }
          this.timeOut = new Date();
          const api = this.$fetchApi.judgeBlackInfo.api;
          const judgeBlackInfoData = await this.$fetchData.fetchPost(
            {},
            api,
            "json"
          );
          if (judgeBlackInfoData.code === "200") {
            if (judgeBlackInfoData.result === 1) {
              this.checkModuleData(data.name);
            } else if (judgeBlackInfoData.result === 0) {
              Message("你在黑名单内,暂不能进入服务");
            }
          }
        } else {
          this.$router.push({
            name: data.name
          });
        }
      } else {
        if (data.text === '财务申请' && this.isNoNanfanPerson == 'true') {
          this.$router.push('/formDetails?&area=HAINAN&code=REQUEST&type=handle');
        } else if (data.text === '财务申请' && this.isNoNanfanPerson == 'false') {
          Message("暂无权限进入该服务");
        } else if (data.text === '文档管理') {
          this.$router.push('/documentManagement');
        } else {
          window.open(data.path);
        }
      }
    },
    async checkModuleData(name) {
      const checkModuleApi =
        this.$fetchApi.checkModule.api + "/be9b8aafd42546e1a5ee86b34e16cdd6";
      const checkModuleData = await this.$fetchData.fetchPost(
        {},
        checkModuleApi,
        "json"
      );
      if (checkModuleData.code === '200') {
          if (checkModuleData.result.status === 0) {
      this.$router.push({
        name
      });
          } else if (checkModuleData.result.status === 1) {
              Message('后台已关闭');
          } else if (checkModuleData.result.status === 2) {
              Message('现在不在备案时间');
          }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.indexLeft {
  width: 393px;
}
.search_input {
  display: flex;
  align-items: center;
  justify-content: center;
  .left_search {
    flex: 1;
    line-height: 52px;
    /deep/ .el-input {
      height: 52px;
      line-height: 52px;
      .el-input__inner {
        height: 52px;
        line-height: 52px;
      }
    }
  }
  .search_icon {
    margin-left: -2px;
    width: 52px;
    height: 52px;
    background-color: #3380fe;
    border-radius: 0px 5px 5px 0px;
    z-index: 100;
    cursor: pointer;
    text-align: center;
    line-height: 50px;
    img {
      width: 24px;
      height: 24px;
      vertical-align: middle;
      transition: 0.5s;
    }
    &:hover img {
      transform: scale(1.15);
    }
  }
}
.hotSearch {
  color: #999999;
  font-size: 14px;
  margin: 12px 0 24px;
  a {
    margin-right: 5px;
  }
}
.consultation {
  .newsTitle {
    cursor: pointer;
  }
  .newsList {
    margin-top: 10px;
  }
  p.title {
    color: #3380fe;
    font-size: 20px;
    margin: 41px 0 20px;
  }
  p.title::before {
    content: "";
    width: 4px;
    height: 20px;
    background-color: #3380fe;
    display: inline-block;
    margin-right: 9px;
    position: relative;
    top: 3px;
  }
  li {
    color: #333333;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  li:hover {
    color: #3380fe;
  }
  .dataQuery {
    margin-top: 20px;
    li div {
      cursor: pointer;
    }
    li {
      margin-bottom: 0;
      p {
        margin-top: 12px;
      }
    }
    .dataQueryItem {
      div {
        width: 60px;
        height: 60px;
        background-color: #f6ab62;
        border-radius: 50%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    li:nth-child(1) .dataQueryItem div {
      background-color: #6299f1;
    }
    li:nth-child(1) .dataQueryItem div:hover {
      background: #3f84f4;
    }
    li:nth-child(1):hover .dataQueryItem {
      color: #3f84f4;
    }
    li:nth-child(2) .dataQueryItem div {
      background-color: #e671ae;
    }
    li:nth-child(2) .dataQueryItem div:hover {
      background: #e65ba3;
    }
    li:nth-child(2):hover .dataQueryItem {
      color: #e65ba3;
    }
    li:nth-child(3) .dataQueryItem div {
      background-color: #f6ab62;
    }
    li:nth-child(3) .dataQueryItem div:hover {
      background: #f59f4b;
    }
    li:nth-child(3):hover .dataQueryItem {
      color: #f59f4b;
    }
    li:nth-child(4) .dataQueryItem div {
      background-color: #9dc96d;
    }
    li:nth-child(4) .dataQueryItem div:hover {
      background: #85bb49;
    }
    li:nth-child(4):hover .dataQueryItem {
      color: #85bb49;
    }
    li:nth-child(5) .dataQueryItem div {
      background-color: #6299f1;
    }
    li:nth-child(5) .dataQueryItem div:hover {
      background: #3f84f4;
    }
    li:nth-child(5):hover .dataQueryItem {
      color: #3f84f4;
    }
    .businessGuide {
      margin-bottom: 20px;
      transition: 0.5s;
      p {
        margin-top: 0;
      }
      i {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }
    }
    .businessGuide:hover {
      background-color: #3380fe;
      color: #fff;
    }
    .businessGuide:nth-child(1):hover i {
      background-image: url("../../../assets/images/index/nfba-w.png") !important;
    }
    .businessGuide:nth-child(2):hover i {
      background-image: url("../../../assets/images/index/btsq-w.png") !important;
    }
    .businessGuide:nth-child(3):hover i {
      background-image: url("../../../assets/images/index/nfbg-w.png") !important;
    }
    .businessGuide:nth-child(4):hover i {
      background-image: url("../../../assets/images/index/xxbs-w.png") !important;
    }
  }
  .businessGuideContent {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .businessGuide {
    width: 170px;
    height: 40px;
    border-radius: 10px;
    border: solid 1px #448afc;
    justify-content: center;
    margin-bottom: 20px;
    background-color: #e8f0ff;
    color: #3380fe;
    img {
      margin-right: 10px;
    }
  }
  .businessGuide:nth-child(2n-1) {
    margin-left: 13px;
  }
}
.consultation:last-child {
  padding-bottom: 100px;
}
</style>